import { Col, Container, PageBanner, Row, SocialButton, Thumbnail, Text, Title } from '@zigurous/react-components'; // prettier-ignore
import { Link as GatsbyLink, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import { ImageProps, Page } from '../components';
import { trackEvent } from '../firebase/analytics';

export const query = graphql`
  query Games {
    json: allGamesJson {
      games: nodes {
        id: jsonId
        title
        subtitle
        image {
          sharp: childImageSharp {
            original {
              src
              width
              height
            }
          }
        }
      }
    }
  }
`;

const metadata = {
  url: 'https://zigurous.com/games',
  title: 'Zigurous • Games',
  description:
    'Zigurous is working on their next game Zeek Will Freak, a procedurally generated action RPG game where players will fight for survival from the torments of their own mind.',
};

function Games({ data, location }) {
  const { games } = data.json;
  return (
    <Page className="games" location={location} metadata={metadata}>
      <PageBanner className="bg-surface-1">
        <Container fluid>
          <Row className="align-items-center">
            <Col xl={5}>
              <Title subtitle size={4}>
                Zeek Will Freak
              </Title>
              <Title nowrap size={1}>
                In Development
              </Title>
              <SocialButton
                className="margin-top-md margin-bottom-xxl"
                link="twitch"
                onClick={() => {
                  trackEvent('social_button', {
                    content_type: 'button',
                    event_category: 'engagement',
                    event_label: 'Watch on Twitch',
                  });
                }}
                shape={SocialButton.shape.rounded}
                style={SocialButton.style.outline}
              >
                Watch on Twitch
              </SocialButton>
            </Col>
            <Col xl={7}>
              <Text size="lg">
                Zeek Will Freak is a procedurally generated action RPG game
                where players will fight for survival from the torments of their
                own mind. The game tells a deeply personal story of a character
                named Zeek who must learn to balance their inner forces of light
                and dark. Ultimately you decide how this story ends. Are you
                strong enough to withstand the evils lurking in your own head,
                or will you come out the other end a broken monster?
              </Text>
              {/* <ul className="font-sm">
              <li>Procedurally generated worlds</li>
              <li>Dynamic combat and survival mechanics</li>
              <li>Dungeon instancing and bosses</li>
              <li>Tarot-based skill cards</li>
              <li>Global inventory system</li>
            </ul> */}
            </Col>
          </Row>
        </Container>
      </PageBanner>
      <Container className="margin-top-xxxl" fluid>
        <Row>
          <Col>
            <Title size={3}>Legacy Games</Title>
          </Col>
        </Row>
        <Row>
          {games.map((game, index) => (
            <Col className="padding-lg" key={game.id} md={6} lg={4}>
              <Thumbnail
                alt={game.title}
                animated
                aria-label={game.title}
                ElementType={GatsbyLink}
                height={game.image.sharp.original.height}
                image={game.image.sharp.original.src}
                index={index}
                onClick={() => {
                  trackEvent('thumbnail', {
                    content_type: 'image',
                    event_category: 'engagement',
                    event_label: game.title,
                    item_id: game.id,
                  });
                }}
                to={`/games/${game.id}`}
                width={game.image.sharp.original.width}
              />
              {/* <Title bold marginTop="md">
                {game.title}
              </Title>
              <Title eyebrow>{game.subtitle}</Title> */}
            </Col>
          ))}
        </Row>
      </Container>
    </Page>
  );
}

Games.propTypes = {
  data: PropTypes.shape({
    json: PropTypes.shape({
      games: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          title: PropTypes.string,
          subtitle: PropTypes.string,
          path: PropTypes.string,
          image: ImageProps,
        })
      ),
    }),
  }),
  location: PropTypes.object,
};

export default Games;
